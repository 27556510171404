.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 1000;
  cursor: pointer;
}

.scroll-icon {
  width: 40px;
  height: 40px;
  color: #ffffff;
  transition: color 0.3s ease;
}

.scroll-icon:hover {
  color: #1e90ff; /* Light blue hover color */
}
