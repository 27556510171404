/* /* Header.css */

@media (min-width: 1000px) {
  .rightC {
    margin-left: 25px;
  }

  .leftC {
    margin-left: -40px;
  }
}

.leftC {
  margin-top: -120px;

}

@media (max-width: 991px) {
  .leftC {
    order: 1; /* Ensure the left content (motion div) appears on top */
    margin-top: 50px;
  }
  .rightC {
    order: 2; /* Ensure the right content (text) appears below the motion div */
    top: -90px;
  }

  #xxx {
    font-size: 2.4rem; /* Smaller font size for smaller screens */
    padding: 40px 10px; /* Adjust padding for smaller screens */
    margin-top: -110px;
    
  }

  .leftC, .rightC {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  p {
    font-size: 16px; /* Adjust font size for smaller screens */
    line-height: 1.4; /* Adjust line height for better readability */
  }
}


@media (min-width: 995px){
  #xxx {
    font-size: 3.4rem; /* Smaller font size for smaller screens */
    padding: 40px 10px; /* Adjust padding for smaller screens */
    margin-bottom: 110px !important;
    
  }
}