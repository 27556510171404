.carousel-control-prev-icon {
  /* Change the color */
  color: white;

  /* Change the position */
  left: 25%; /* Adjust as needed */
  margin-left: -63px;
  transform: scale(1.5);
}

.carousel-control-next-icon {
  /* Change the color */
  color: white;

  /* Change the position */
  right: 25%; /* Adjust as needed */
  margin-right: -103px;
  transform: scale(1.5);

  
}

@media (max-width: 1100px){
  .carousel-control-next-icon{
    margin-right: 500px !important;
  }

  .carousel-control-prev-icon{
    margin-left: 500px !important;
  }
}

/* @media (min-width: 1020px){
  .carousel-control-prev-icon{
    margin-left: -104px !important;
  }
} */

