@media (max-width: 600px) {
    .logo-container {
      margin-left: 20px; /* Adjust the margin-left as needed */
    }
  }
  
  @media (max-width: 600px) {
    #headerP {
      font-size: 1.6rem; /* Adjust the font size as needed */
    }
  }
  
  @media (max-width: 765px) {
    .image-container12 {
       /* Adjust the left margin as needed */
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 115px;
      
    }
    .custom-list{
      margin-left: -30px;
    }
    .contactus{
      position: relative;
      top: 50px;
      
    }
    
  }
#socialnote{
  margin-top: 10px;
}
  @media (max-width: 400px) {
    .image-container12 {
     /* Adjust the left margin as needed */
     position: absolute;
     left: 50%;
     transform: translateX(-50%);
     top: 145px;
    }
   
  }

.social{
  margin-right: 650px;
}

@media (max-width: 700px) {
  .social{
    margin-right: 0px;

  }
  
  
}



@media (max-width: 600px) {
  .mobile-font-size {
      font-size: 1.0rem; /* Adjust this value as needed */
  }
  .c1{
    margin-left: 22px;
  }
  
}

@media (max-width: 400px) {
  .mobile-font-size {
      font-size: 2.5rem !important; /* Adjust this value as needed */
  }
  .c1{
    margin-left: 22px;
  }
  
}

@media (min-width: 601px) and (max-width:1100px) {
  .mobile-font-size {
      font-size: 28px !important; /* Adjust this value as needed */
  }
  .gallerycard{
    width: '10px' !important;
  }
  
  
}
/* App.css */

/* Ensure this rule applies only in responsive mode */
@media (max-width: 600px) {
  .navbar-toggler {
    margin-right: 20px;
  }
  .nav-link {
    margin-left: 10px; /* Adjust the margin as needed */
  }
  #preg{
    margin-top: 15px;
  }
  #px{
    margin-top: 15px;
  }
}



@media (max-width: 400px){
  .c1{
    margin-left: 25px;
  }
  .navbar-toggler {
    margin-right: 20px; /* Adjust the margin as needed */
  }
  #preg{
    margin-top: 15px;
  }

  #px{
    margin-top: 15px;
  }
}


@media (min-width: 601px) {
  .navbar-toggler {
    margin-right: 30px; /* Adjust the margin as needed */
  }
  .nav-link {
    margin-left: 30px; /* Adjust the margin as needed */
  }
  .logo-container {
    margin-left: 20px; /* Adjust the margin-left as needed */
  }
}

.custom-footer {
  min-height: 400px; /* Adjust this value as needed */
}
@media (max-width: 1200px) {
  .links-column {
    position: static !important;
    text-align: center !important;
    margin-top: 70px;
   
  }
  #hlink{
    margin-left: 21px;
  }
  #l1{
    margin-left: 20px;
  }

  #l7{
    margin-left: 23px;
  }
  
}

@media (min-width: 1200px) {
  .links-column {
    position: absolute !important;
    top: 0 !important;
    right: 0px !important;
    text-align: right !important;
  }
}

@media (max-width: 600px) {
  #competition,
  #details {
    display: inline;
  }
}


@media (max-width: 700px) {
  .carousel1 img{
    width: 90% !important;
  }
}


@media (max-width: 600px){
  #h11{
    top: 60px !important;
  }
}
/* Default footer height */


/* Media query for screens with a minimum width of 1100px */
@media screen and (min-width: 1100px) {
  .responsive-footer {
    height: 300px; /* Set your desired footer height for large screens here */
  }
}
