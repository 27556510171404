@media (max-width: 768px) {
    .custom-card23 {
      width: -5% !important;
      
    }
  }
  
  @media (max-width: 400px){
    .row1{
        margin-right: 10px;
    }
  }

  @media(min-width:401px) and (max-width: 700px){
    .row1{
        margin-right: 16px;
    }
  }


  .carousel-indicators {
    bottom: -30px; /* Adjust as needed to position below the card */
  }

  @media (min-width: 992px) and (max-width: 1125px) {
    .responsive-font-size {
      font-size: 13px; /* Adjust this value to your desired font size */
    }
    .responsive-font-size1 {
      font-size: 18px; /* Adjust this value to your desired font size */
    }
  }
  

  