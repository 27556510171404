html, body {
    overflow-x: hidden;
    
    width: 100%;
    height: 100%;
}

h1 {
    text-align: center;
    margin-top: 14rem;
    font-size: 3rem;
}

.body{
    background:#010e2b ;
    
}

/* styles.css */
/* .ParticleWrapper {
    position: absolute;
    width: 100%;
} */

.gradient-top-left {
    position: absolute;
    width: 900px;
    height: 900px;
    border-radius: 50%;
    opacity: 0.16;
    left: -3px;
    top: -5px;
    background: radial-gradient(circle closest-side, #6d8cf3, #010e2b);
}

@media (max-width: 1440px) {
    .gradient-top-left {
        width: 450px;
        height: 450px;
    }
}

.top-center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -45px;
    left: 30px;
    width: 100%;
}

.logo-wrapper {
    margin-bottom: 100px; /* Adjust margin as needed */
}
#logo-image2{
    margin-top: 75px;
    margin-left: 10px;
}
.vertical-line {
    width: 0.5px;
    height: 50px; /* Adjust height as needed */
    background-color: white;
    margin-bottom: 90px;
    margin-left: 10px;
    margin-right: -20px; /* Adjust margin as needed */
    opacity: 50%;
}


.secondaryText1 {
    color: white;
    font-size: 10px;
    letter-spacing: 2px;
    word-spacing: 5px;
    font-family: "Montserrat";
    text-align: center;
}

.cd-timer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.cd-container .time {
    font-weight: bold;
    padding: 1rem 2rem;
    font-size: 3rem;
    border: 4px solid #01dfcc;
    border-radius: 1rem;
    margin: 0 1rem;
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.5);
}

.cd-container span {
    font-size: 1rem;
    font-weight: bold;
    color: #ccc;
}

.hero-wrapper {
    /* background-color: #010e2b; */
    color: white;
    position: relative;
    padding-bottom: 5rem; /* Increase the padding to increase the height */
    z-index: 1;
    background-size: cover; /* Adjust background size as needed */
    background-position: center;
    gap: 50px;
}


.hero-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 2rem;
}

.hero-left {
    gap: 3rem;
    flex: 1;
    position: relative;
    top: 115px;
    
}

#passage {
    line-height: 10px;
    position: relative;
    top: 80px;
}

.hero-title {
    position: relative;
    top: -110px;
    z-index: 1;
    line-height: 80px;
}

.hero-title > h1 {
    font-weight: 900; /* Increased numeric value for font-weight */
    font-size: 5.9rem;
    font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif; /* Using a bold variant if available */
    /* Slightly thicker text-shadow for a bolder effect */
    line-height: 80px;
    letter-spacing: 7px;
}





.orange-circle {
    width: 4rem;
    height: 4rem;
    background: var(--orange-gradient);
    border-radius: 999px;
    position: absolute;
    right: 28%;
    top: -10%;
    z-index: -1;
}

.search-bar {
    background-color: white;
    border-radius: 5px;
    border: 3px solid rgba(120,120,120,0.374);
    padding: 0.5rem 1rem;
    justify-content: space-between;
    width: 100%;
}

.search-bar>input {
    border: none;
    outline: none;
}

.stats {
    width: 100%;
    justify-content: space-between;
    gap: 10px;
}

.stat>:nth-child(1) {
    font-size: 2rem;
}

.stat>:nth-child(1)>:last-child {
    color: orange;
}

.image-container {
    
    width: 50rem;
    height: 35rem;
    overflow: hidden;
    position: relative;
    top: -30px;
    margin-left: 40px;
   
}

.image-container>img {
    width: 100%;
    height: auto;
    
}

.glass-container {
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.glass-container span {
    color: white;
    font-size: 10px;
}

.h-container {
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
}

.h-item {
    margin-right: 2px;
}

.container1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    bottom: 70px;
    left: 0;
}

.container1 > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

#div2 {
    margin-right: 20px;
}

.hero-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    position: relative;
    top: 70px;
}

#particles canvas {
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
}



/* #particles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #010e2b;
    z-index: -1;
  } */

.h-wrapper {
    background: var(--black);
    z-index: 9999;
}

.h-container {
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: var(--secondary);
    z-index: 9999;
}

.flexCenter1 {
    display: flex;
    column-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

#logo-right {
    margin-bottom: 20px;
    margin-right: 30px;
}

#c1 {
    position: relative;
    top: -85px;
}


/* Existing Media Query for 390px to 750px */
@media (max-width: 800px) and (min-width: 400px) {
    html, body {
        overflow-x: hidden;
        width: 100%;
        height: 100%;
    }
    #logo-image1 {
        width: 100px !important;
        margin-bottom: -160px !important;
         /* Adjust width as needed */
    }
    #logo-image2 {
        
       margin-bottom: -140px !important;
        height: 180px;
        width: 200px; /* Adjust width as needed */
    }
    .top-center-container{
        top: -90px !important;
        left: 23px;
    }
    
    .vertical-line {
        height: 45px;
        margin-right: -15px;
        margin-bottom: -70px !important;
        margin-left: 25px;
         /* Adjust height as needed */
    }
    
    .glass-container {
        padding-top: 30px;
        height: 70px !important;
        width: 70px !important;
        margin: 5px;
    }
   
    .image-container {
        margin-left: 28px;
        top: 15px;
        margin-top: 20px;
    }
    
    .container1 {
        flex-direction: column;
        position: absolute;
        transform: translateX(-50%);
    }

    .glass-container .secondaryText {
        font-size: 9px;
        margin-bottom: -2px;
    }
    
    .glass-container span {
        line-height: 20px;
    }

    #c1 {
        flex-direction: row;
        margin-top: 70px;
        gap: 10px;
    }

    .glass-container {
        width: 60px;
        height: 60px;
    }

    #passage {
        margin-bottom: 10px;
        top: -60px;
    }

    .image-container > img {
        width: 500px !important;
        height: auto !important;
        margin-top: 35px;
        margin-left: -60px;
    }

    .hero-title > h1 {
        font-size: 3.9rem;
        line-height: 60px;
        margin-top: 30px;
        margin-bottom: 28px;
    }

    .hero-container {
        flex-direction: column;
        gap: 1rem;
    }

    .hero-left,
    .hero-right {
        width: 100%;
        text-align: center;
    }

    .hero-left {
        order: 1;
        top: -10px;
    }

    .hero-right {
        order: 2;
        top: 70px;
    }

    .image-container {
        width: 100%;
        height: auto;
    }

    .hero-title {
        top: -65px;
        font-size: 1rem;
        letter-spacing: 3px;
        margin-bottom: -20px;
    }

    .stats {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .container1 {
        flex-direction: row;
        align-items: center;
        bottom: -50px;
        gap: 0.5px;
        left: 47%;
    }

    #mit{
        width: 60px;
    }

    .container1 img {
        width: 30px; /* Adjust the width as needed */
      }

    .secondaryText1 {
        font-size: 12px;
        margin-top: -4px;
    }

    .glass-container {
        margin: 2px;
    }

    .secondaryText {
        font-size: 20px;
    }
}

/* New Media Query for max-width 385px */
@media (max-width: 395px) {
    #logo-image1 {
        width: 100px !important;
        margin-bottom: -190px !important;
         /* Adjust width as needed */
    }
    #logo-image2 {
        
       margin-bottom: -170px !important;
        height: 220px;
        width: 200px; /* Adjust width as needed */
    }
    .top-center-container{
        top: -110px !important;
        left: 23px;
    }
    
    .vertical-line {
        height: 45px;
        margin-right: -15px;
        margin-bottom: -100px !important;
        margin-left: 25px;
         /* Adjust height as needed */
    }
    .glass-container {
        padding-top: 30px;
        height: 50px !important;
        width: 50px !important;
        margin: 5px;
    }
    .d1{
        font-size: 20px !important;
    }
   
    .image-container {
        margin-left: -30px;
        top: 15px;
        margin-top: 25px;
    }
    
    .container1 {
        flex-direction: column;
        position: absolute;
        transform: translateX(-50%);
    }

    .glass-container .secondaryText {
        font-size: 6px !important; /* Adjusted font-size */
        margin-bottom: -2px;
    }
    
    .glass-container span {
        line-height: 20px;
    }

    #c1 {
        
        flex-direction: row;
        top: 10px;
        gap: 10px;
    }
    

    .glass-container {
        width: 60px;
        height: 60px;
    }

    #passage {
        top: -70px;
        
    }

    .image-container > img {
        width: 500px !important;
        height: auto !important;
        margin-top: 25px;
        margin-left: -40px;
    }

    .hero-title > h1 {
        font-size: 3.9rem;
        line-height: 60px;
        margin-top: -50px;
        margin-bottom: 28px;
    }

    .hero-container {
        flex-direction: column;
        gap: 1rem;
    }

    .hero-left,
    .hero-right {
        width: 100%;
        text-align: center;
    }

    .hero-left {
        order: 1;
        top: -7px;
    }

    .hero-right {
        order: 2;
        top: 90px;
    }

    .image-container {
        width: 100%;
        height: auto;
    }

    .hero-title {
        top: -15px;
        font-size: 1rem;
        letter-spacing: 3px;
        margin-bottom: -20px;
    }

    .stats {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .container1 {
        flex-direction: row;
        align-items: center;
        bottom: -50px;
        gap: 0.5px;
        left: 47%;
    }

    #mit{
        width: 60px;
    }

    .container1 img {
        width: 30px; /* Adjust the width as needed */
      }

    .secondaryText1 {
        font-size: 12px;
        margin-top: -4px;
    }

    .glass-container {
        margin: 2px;
    }

    .secondaryText {
        font-size: 18px;
        margin-top: -4px;
    }
}


@media (min-width: 802px) and (max-width: 1300px) {
    .secondaryText{
        font-size: 14px !important;
    }
    #c1 {
        margin-bottom: 85px;
        gap:5px !important;
    }
    .hero-title>h1 {
        font-size: 4.1rem;
        line-height: 60px;

        
    }
    .image-container{
        width: 35rem;
        height: 25rem;
        /* margin-left:-110px ; */
    }
    
    .container1{
        top:120px;
    }

    .hero-left{
        top: 170px;
    }

    .hero-right{
        top: 80px;
    }
}

