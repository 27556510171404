html {
  overflow-x: hidden; /* Prevent horizontal overflow */
}
@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_nova_rgbold';
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'adelle_rgregular';
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
body{
	font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
}
h1{
   font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif;
}
body {
  /* Adjust the path to your background image if necessary */
  background-repeat: round;
  background-size: 800px 800px;
  background-attachment: fixed;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#010e2b !important ;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-family: 'proxima_nova_rgbold', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.card {
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 576px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 768px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 992px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 1200px) {
  .card {
    margin-left: 10px;
    margin-right: 10px;
  }
}




/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_nova_rgbold';
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'adelle_rgregular';
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}


:root {
  --primary: #010e2b;
  --secondary: rgba(255, 255, 255, 0.78);
  --black:#010e2b;
  --blue-gradient: linear-gradient(97.05deg, #4066ff 3.76%, #2949c6 100%);
  --orange-gradient: linear-gradient(270deg, #ffb978 0%, #ff922d 100%);
  --blue:#010e2b;
  --lightBlue: #eeeeff;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}
a {
  color: inherit;
  text-decoration: none;
}
.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: #1f3e72;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: white;
  font-size: 20px;
  letter-spacing: 2px;
  word-spacing: 5px;
  font-family: "Montserrat";

}
.orangeText {
  color: orange;
  font-size: 1.5rem;
  font-weight: 600;
}

/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}